import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import('layouts/Layout.vue'),
        children: [{ path: '', component: () => import('pages/IndexPage.vue') }],
    },

    // Always leave this as last one,
    // but you can also remove it
    {
        path: '/:catchAll(.*)*',
        // component: () => import('pages/ErrorNotFound.vue')
        component: () => import('pages/WorkInProgress.vue'),
    },
];

export default routes;
