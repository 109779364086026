import { defineStore } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';
import { Screen } from 'quasar';
import { EViewPort } from 'src/enums/view-port.enum';

export const useViewPortStore = defineStore('viewPort', () => {
    let viewPort: EViewPort;
    const getViewPort: ComputedRef<EViewPort> = computed(() => viewPort);
    function onInit(): void {
        viewPort = Screen.gt.sm ? EViewPort.DESKTOP : EViewPort.MOBILE;
    }

    return { getViewPort, onInit };
});
