export const CUSTOM_ICONS: {[n: string]: string} = {
    'app:i-angular': 'img:app-icons/technologies/icon-angular.svg',
    'app:i-angularjs': 'img:app-icons/technologies/icon-angularjs.svg',
    'app:i-electron': 'img:app-icons/technologies/icon-electron.svg',
    'app:i-mongodb': 'img:app-icons/technologies/icon-mongodb.svg',
    'app:i-mysql': 'img:app-icons/technologies/icon-mysql.svg',
    'app:i-node': 'img:app-icons/technologies/icon-node.svg',
    'app:i-php': 'img:app-icons/technologies/icon-php.svg',
    'app:i-vue': 'img:app-icons/technologies/icon-vue.svg',
    'app:i-vue-color': 'img:app-icons/technologies/icon-vue-color.svg',
    'app:i-quasar': 'img:app-icons/technologies/icon-quasar.svg',
    'app:i-pinia': 'img:app-icons/technologies/icon-pinia.svg',
    'app:i-ts': 'img:app-icons/technologies/icon-typescript.svg',
    'app:i-nextjs': 'img:app-icons/technologies/icon-nextjs.svg',
    'app:i-react': 'img:app-icons/technologies/icon-react.svg',
    'app:i-threejs': 'img:app-icons/technologies/icon-threejs.svg',
    'app:i-supabase': 'img:app-icons/technologies/icon-supabase.svg',
    'app:i-anu': 'img:app-icons/technologies/icon-anu.svg',
    'app:i-p5': 'img:app-icons/technologies/icon-p5.svg',
    'app:i-unocss': 'img:app-icons/technologies/icon-unocss.svg',
    'app:i-braces': 'img:app-icons/icon-braces.svg',
    'app:i-www': 'img:app-icons/icon-www.svg',
    'app:i-click': 'img:app-icons/icon-click.svg',
    'app:i-success-outline-star': 'img:app-icons/icon-success-outline-star.svg',
    'app:i-linkedin': 'img:app-icons/icon-linkedin.svg',
};
