<template>
    <router-view />
</template>

<style src="@quasar/quasar-ui-qmarkdown/dist/index.css"></style>

<script setup lang="ts">
import { useViewPortStore } from 'stores/viewPort';
import { onMounted } from 'vue';
import { useMeta, useQuasar } from 'quasar';
import { CUSTOM_ICONS } from 'src/const/icons.const';

const $q = useQuasar();

$q.iconMapFn = (iconName) => {
    const icon = CUSTOM_ICONS[iconName]
    if (icon !== undefined) {
        return { icon };
    }
}

const viewPortStore = useViewPortStore();

useMeta({
    title: 'HaHa Dev | Full-Stack Web Developer',
    meta: {
        description: { name: 'description', content: 'Doświadczony Full-Stack Web Developer specjalizujący się w JavaScript, Node.js, PHP, MongoDB i MySQL. Tworzę wydajne, skalowalne aplikacje webowe dla firm z różnych branż.' },
        keywords: { name: 'keywords', content: 'full-stack developer, web developer, JavaScript, React, Node.js, Vue.js, TypeScript, REST API, database design, cloud computing, DevOps, responsive web design, performance optimization, Poznan web developer, hubert hruswicki, hruświcki, progressive web apps (PWA), mobile-first development, front-end development, back-end development, PHP, MongoDB, MySQL, Nuxt' },
        equiv: { 'http-equiv': 'Content-Type', content: 'text/html; charset=UTF-8' },
        viewport: { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
        ogTitle: { property: 'og:title', content: 'Hubert Hruświcki | Developer Portfolio' },
        ogDescription: { property: 'og:description', content: 'Zobacz moje projekty i umiejętności' },
        robots: { name: 'robots', content: 'index, follow' }
    },
    link: {
        canonical: { rel: 'canonical', href: 'https://haha-dev.pl/' },
        favicon: { rel: 'icon', href: 'favicon.ico' }
    },
    script: {
        ldJson: {
            type: 'application/ld+json',
            innerHTML: '{ "@context": "http://schema.org", "@type": "Person", "name": "Hubert Hruświcki", "jobTitle": "Full-Stack Web Developer", "url": "https://haha-dev.pl", "sameAs": [ "https://github.com/latinox33", "https://www.linkedin.com/in/hubert-hru%C5%9Bwicki-b436b2168/" ], "knowsAbout": ["JavaScript", "Node.js", "Vue.js", "Nuxt", "PHP", "MySQL", "MongoDB"] }'
        }
    }
})
onMounted(() => {
    viewPortStore.onInit();
});
</script>
